// src/config/menu.ts
import { levels } from "@/lib/script-levels";

export const getScaryNavTitle = (currentGameLevel: number | null | undefined): string => {
  if (currentGameLevel === null || currentGameLevel === undefined) return "Scary!";
  if (currentGameLevel === levels.length - 1) return "Master!"; // Level 10 is the finish line
  if (currentGameLevel === 0) return "Scary!";
  return `Level ${currentGameLevel + 1}`;
};

interface NavItem {
    title: string
    to?: string
    href?: string
    disabled?: boolean
    external?: boolean
    label?: string
    hidden?: boolean
}

interface NavItemWithChildren extends NavItem {
    items?: NavItemWithChildren[]
}

// Data-dependent navigation items (used in main navbar)
export const dataNav: NavItem[] = [
    {
        title: "Analysis",
        href: "/analysis",
    },
    {
        title: "Trends",
        href: "/trends",
    },
    {
        title: "Keywords",
        href: "/keywords",
    },
    {
        title: "Placements",
        href: "/placements",
    },
    {
        title: "Curve",
        href: "/curve",
    },
    {
        title: "Hourly",
        href: "/hourly",
    },
    // Add key sales pages to main nav
    {
        title: "Buy",
        href: "/buy",
    },
    {
        title: "4Cs",
        href: "/4cs",
    },
    {
        title: "CPD",
        href: "/cpd",
    }
];

// Sales navigation items (for sales layout pages)
export const salesNav: NavItem[] = [
    {
        title: "Mobile",
        href: "/mobile",
    },
    {
        title: "Build",
        href: "/build",
    },
    {
        title: "PMax",
        href: "/pmax",
    },
    {
        title: "Mastery",
        href: "/mastery",
    },
    {
        title: "Amsterdam",
        href: "/amsterdam",
    },
    {
        title: "Buy",
        href: "/buy",
    },
    {
        title: "4Cs",
        href: "/4cs",
    },
    {
        title: "CPD",
        href: "/cpd",
    },
    {
        title: "Slides",
        href: "/slides",
    }
];

// Tutorial navigation items
export const tutorialNav: NavItem[] = [
    {
        title: "Scary",
        href: "/scary",
    },
    {
        title: "NLE",
        href: "/nle",
    },
    {
        title: "PSA",
        href: "/psa",
    },
    {
        title: "Script Help",
        href: "/script-help",
    }
];

// Dev navigation items
export const devNav: NavItem[] = [
    {
        title: "Data",
        href: "/data",
    },
    {
        title: "Test",
        href: "/test",
    },
    {
        title: "Plot",
        href: "/plot",
    },
    {
        title: "Prompt",
        href: "/prompt",
    },
    {
        title: "Brief",
        href: "/brief",
    },
    {
        title: "Groups",
        href: "/groups",
    }
];

// Utility/hidden navigation items
export const utilityNav: NavItem[] = [
    {
        title: "Home",
        href: "/",
        hidden: true,
    },
    {
        title: "Setup",
        href: "/setup",
        hidden: true,
    },
    {
        title: "Settings",
        href: "/settings",
        hidden: true,
    },
    {
        title: "Story",
        href: "/story",
        hidden: true,
    }
];

export const getUpdatedNav = (currentGameLevel: number | null | undefined): NavItem[] => {
    return [
        ...utilityNav,
        ...dataNav,
        {
            title: getScaryNavTitle(currentGameLevel),
            href: "/scary"
        }
    ];
};